import React, {useState} from 'react';
import { Popover, Button, MenuItem, Chip, Modal, Tooltip, Alert, IconButton, Form, InsetInput, Loader } from '@heathmont/moon-core-tw';
import { GenericSettings, NotificationsSettings, GenericDelete, GenericUsers, GenericInfo, ControlsCloseSmall, GenericPlus, GenericAlarm } from '@heathmont/moon-icons-tw';
import { useNavigate } from 'react-router-dom';

import EventForm from './EventForm';

import axios from 'axios';

const EventSettings = ({event = null, setEvent, user}) => {


    const navigate = useNavigate();

    function handleRedirect() {
        navigate(`/`);
    }


    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //       UPDATE EVENT MODAL
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

    const [isModalOpen, setModalOpen] = useState(false);

    const closeModal = () => setModalOpen(false);
    const openModal = () => setModalOpen(true);


    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //             DELETE EVENT 
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const closeDeleteModal = () => setIsDeleteOpen(false);
    const openDeleteModal = () => setIsDeleteOpen(true);
    const [isDeleted, setIsDeleted] = useState(false);


    function handleDelete(){

    
        axios.delete(`https://eventio.hasura.app/api/rest/event/delete/${event.id}`)
          .then(response => {
            console.log("Odpoveď od servera:", response.data);
            let confirm = response.data?.delete_events_by_pk?.id ? response.data.delete_events_by_pk.id : null;
            if(confirm){
              setEvent(null);
              setIsDeleted(true);
            }
          })
          .catch(error => {
            console.error("Chyba pri odosielaní údajov na server:", error);
          });
    
    }

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%



    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    //          MANAGE PERMISSIONS
    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

    const [permModal, setPermModal] = useState(false);
    const closePermModal = () => {
      setPermModal(false);
      setUserNotFound(false);
      setPermLoading(false);
    };
    const openPermModal = () => setPermModal(true);

    const [permFormUser, setPermFormUser] = useState("");
    const [permLoading, setPermLoading] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);

    const handleAddUser = (e) => {

      e.preventDefault();
      setUserNotFound(false);
      setPermLoading(true);

      axios.get(`https://eventio.hasura.app/api/rest/finduser/${permFormUser}`)
        .then(res => {


          if(res.data.users.length > 0){

            let dataToSend = {
              user_email: res.data.users[0].email,
              event_id: event.id
            }

            axios.post(`https://eventio.hasura.app/api/rest/permission/grant`, dataToSend)
              .then(res => {

                setPermLoading(false);

                setEvent(prevEvent => {
                  return {
                    ...prevEvent,
                    eventMembers: [
                      ...prevEvent.eventMembers,
                      {
                        user_email: res.data.insert_eventMembers_one.user_email
                      }
                    ]
                  }
                });

                setPermFormUser("");
                
                
              }).catch(error => {

                  console.error("Chyba pri odosielani na server", error)

              });


          } else {
            setPermLoading(false);
            setUserNotFound(true);
          }

        }).catch(error => {

          console.error("Chyba pri načítaní údajov:", error)

      });

      
    }


    const handleDeleteUser = (user_email) => {
        
        let dataToSend = {
          user_email: user_email,
          event_id: event.id
        }
    
        axios.post(`https://eventio.hasura.app/api/rest/permission/revoke`, dataToSend)

          .then(response => {
            if(response.data?.delete_eventMembers?.affected_rows > 0){
              setEvent(prevEvent => {
                return {
                  ...prevEvent,
                  eventMembers: prevEvent.eventMembers.filter(member => member.user_email != user_email)
                }
              });
            }
          })
          .catch(error => {
            console.error("Chyba pri odosielaní údajov na server:", error);
          });
    
    }

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%


    

  return (
    <>

    {/* Update event */}
    <EventForm type="update" setData={setEvent} event={event} openModal={openModal} closeModal={closeModal} isModalOpen={isModalOpen} setModalOpen={setModalOpen} />

    

    <div className="flex justify-end">
            
        <Popover>
            <Popover.Trigger>
                <Chip
                    className='bg-eventio-primary ml-2 text-goten hover:text-popo'
                    iconOnly={<GenericSettings className="text-moon-24" />}
                    aria-label="Nastavenia udalosti"
                />
            </Popover.Trigger>
            <Popover.Panel className="p-2 flex flex-col gap-1">
                
                        <MenuItem onClick={openModal}>
                        <span className="flex w-11 h-11 bg-gohan items-center justify-center rounded-lg">
                            <NotificationsSettings className="text-bulma text-moon-24" />
                        </span>
                        <MenuItem.MultiTitle
                            title="Upraviť udalosť"
                            text={<span>Nastavenie parametrov</span>}
                        />
                        </MenuItem>

                        {
                          event?.userEvents?.email == user?.email && (
                            <MenuItem onClick={openPermModal}>
                            <span className="flex w-11 h-11 bg-gohan items-center justify-center rounded-lg">
                                <GenericUsers className="text-bulma text-moon-24" />
                            </span>
                            <MenuItem.MultiTitle
                                title="Zdieľanie udalosti"
                                text={<span>Oprávnenia užívateľov</span>}
                            />
                            </MenuItem>
                          )
                        }

                        {
                          event?.userEvents?.email == user?.email && (
                            <MenuItem onClick={openDeleteModal}>
                            <span className="flex w-11 h-11 bg-gohan items-center justify-center rounded-lg">
                                <GenericDelete className="text-bulma text-moon-24" />
                            </span>
                            <MenuItem.MultiTitle
                                title="Odstrániť udalosť"
                                text={<span>Trvalé vymazanie udalosti</span>}
                            />
                            </MenuItem>
                          )
                        }
                        


            </Popover.Panel>
        </Popover>

    </div>

    <Modal open={isDeleteOpen} onClose={closeDeleteModal}>
        <Modal.Backdrop />
        <Modal.Panel>
          <div className="p-4 border-b-2 border-beerus">
            <h3 className="text-moon-18 text-bulma font-medium text-center">
              {isDeleted ? "Udalosť zmazaná!" : "Naozaj chcete udalosť zmazať?"}
            </h3>
          </div>
          <div className="p-4">
            {isDeleted ? (
                <p className="text-moon-sm text-trunks text-center">
                    Udalosť bola úspešne zmazaná. <br />
                    Vráťte sa na domovskú obrazovku
                </p>
            ) : (
                <p className="text-moon-sm text-trunks text-center">
                    Chystáte sa natrvalo odstraniť udalosť: <br />
                    <i className='font-bold'>{event?.name}</i><br />
                    Táto udalosť je nevratná.
                </p>
            )}
            
          </div>
          <div className="p-4 border-t-2 border-beerus flex justify-center">
            {isDeleted ? (
                <>
                    <Button onClick={handleRedirect} className='bg-eventio-primary'>Ok.</Button>
                </>
            ) : (
                <>
                    <Button onClick={closeDeleteModal} className='bg-beerus mr-2 text-popo'>Zrušiť</Button>
                    <Button onClick={handleDelete} className='bg-dodoria'>Áno, zmazať!</Button>
                </>
            )}
          </div>
        </Modal.Panel>
      </Modal>



      <Modal open={permModal} onClose={closePermModal}>
                <Modal.Backdrop />
                <Modal.Panel className="max-w-md">
                    <div className="p-4 border-b-2 border-beerus">
                        <h3 className="text-moon-18 text-bulma font-medium">Zdieľanie udalosti</h3>
                        <IconButton
                            variant="ghost"
                            size="sm"
                            className="absolute top-4 end-5"
                            onClick={closePermModal}
                        >
                            <ControlsCloseSmall className="text-moon-24" />
                        </IconButton>
                    </div>

                    <div className="p-4 ">
                        <form onSubmit={handleAddUser} className='flex flex-row justify-between items-center'>
                            <Form.Item>
                                <InsetInput
                                    value={permFormUser}
                                    name='name'
                                    onChange={(e) => setPermFormUser(e.target.value)}
                                >
                                    <InsetInput.Label>Email používateľa</InsetInput.Label>
                                </InsetInput>
                            </Form.Item>

                            <IconButton disabled={(permFormUser.length > 0) && (!event.eventMembers.find(member => member.user_email == permFormUser)) ? false : true} type="submit" icon={<GenericPlus />} className='bg-eventio-primary ml-4 w-[56px] h-[56px]' />
                        </form>
                    </div>


                    <div className="p-4 border-t-2 border-beerus">
                        <h4 className="text-moon-16 text-bulma font-medium mb-2">Pridaní používatelia:</h4>

                        {
                          permLoading && (
                            <div className="bg-raditz-10 w-full flex justify-between items-center rounded-lg mb-2 py-3 px-3">
                                    <div className='flex items-center'>
                                        <Loader size='xs' color="border-trunks" />
                                        <p className="text-moon-14 font-semibold m-0 ml-4">Prebieha udeľovanie práv...</p>
                                    </div>       
                            </div>
                          )
                        }

                        {
                          userNotFound && (
                            <div className="bg-dodoria-60 w-full flex justify-between items-center rounded-lg mb-2 py-3 px-3">
                                    <div className='flex items-center'>
                                        <GenericAlarm  className='text-moon-32 text-goten' />
                                        <p className="text-moon-14 font-semibold m-0 ml-2">Zadaný používateľ nie je registrovaný!</p>
                                    </div>       
                            </div>
                          )
                        }

                        { event?.eventMembers.length > 0 ? event.eventMembers.map((data) => {
                            return(
                                <div className="bg-eventio-bg-light w-full flex justify-between items-center rounded-lg mb-2 py-3 px-3 overflow-y-scroll" key={data.user_email}>
                                    <div>
                                        <p className="text-moon-14 font-semibold m-0">{data.user_email}</p>
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <Tooltip>
                                            <Tooltip.Trigger className="min-w-4 h-8">
                                                <Chip
                                                    className='py-1 px-1' 
                                                    variant="ghost"
                                                    iconOnly={<GenericDelete className="text-moon-20" />}
                                                    onClick={() => handleDeleteUser(data.user_email)}
                                                />
                                            </Tooltip.Trigger>
                                            <Tooltip.Content position="top-start">
                                                Odstrániť používateľa
                                                <Tooltip.Arrow />
                                            </Tooltip.Content>
                                        </Tooltip>
                                    </div>
                                </div>
                          )}) : (
                              <Alert className="bg-dodoria-10 col-span-6">
                                  <Alert.Message>
                                      <GenericInfo className="mr-1 mt-1" />
                                      Vaša udalosť zatiaľ nebola zdieľaná.
                                  </Alert.Message>
                              </Alert>
                          )
                        } 

                    </div>


                </Modal.Panel>
            </Modal>
    

    </>
  );
};



export default EventSettings;
