import React, {useState} from "react";
import { Input, Popover, Chip, MenuItem, Radio } from '@heathmont/moon-core-tw';
import { GenericSettings, FilesSave, ArrowsChevronLeftDouble, ArrowsChevronRightDouble, GenericDelete } from '@heathmont/moon-icons-tw';


const DataSettings = ({setModule, type, setEvent, item}) => {


    const [tempNames, setTempNames] = useState({});

    function handleNameChange(e, itemName) {
        setTempNames(prevTempNames => ({
            ...prevTempNames,
            [itemName]: e.target.value
        }));
    }

    function handleSaveName(itemName) {
        
        const newName = tempNames[itemName];
        if (newName) {
            changeName(itemName, newName);
            setTempNames(prevTempNames => ({
                ...prevTempNames,
                [itemName]: ''
            }));
        }
    }


    function changeName(oldName, newName) {
        if(type == "event"){
            
            setEvent(prevEvent => {
                const updatedItems = prevEvent.eventData.items.map(item => {
                    if (item.name === oldName) {
                        return { ...item, name: newName };
                    }
                    return item;
                });

                return {
                    ...prevEvent,
                    eventData: {
                        ...prevEvent.eventData,
                        items: updatedItems
                    }
                };
            });
        }

        if(type == "module"){

            setModule(prevModule => {
                const updatedItems = prevModule.moduleData.items.map(item => {
                    if (item.name === oldName) {
                        return { ...item, name: newName };
                    }
                    return item;
                });
                return {
                    ...prevModule,
                    moduleData: {
                        ...prevModule.moduleData,
                        items: updatedItems
                    }
                };
            });
        }
    }


    function handleDelete(itemName) {
        if(type == "event"){
            setEvent(prevEvent => {
                const filteredItems = prevEvent.eventData.items.filter(item => item.name !== itemName);
    
                return {
                    ...prevEvent,
                    eventData: {
                        ...prevEvent.eventData,
                        items: filteredItems
                    }
                };
            });
        }

        if(type == "module"){
            setModule(prevModule => {
                const filteredItems = prevModule.moduleData.items.filter(item => item.name !== itemName);

                return {
                    ...prevModule,
                    moduleData: {
                        ...prevModule.moduleData,
                        items: filteredItems
                    }
                };
            });
        }
    }


    function changeFormat(itemName, newFormat) {
        if(type == "event"){
            setEvent(prevEvent => {
                const updatedItems = prevEvent.eventData.items.map(item => {
                    if (item.name === itemName) {
                        return { ...item, format: newFormat };
                    }
                    return item;
                });
    
                return {
                    ...prevEvent,
                    eventData: {
                        ...prevEvent.eventData,
                        items: updatedItems
                    }
                };
            });
        }

        if(type == "module"){
            setModule(prevModule => {
                const updatedItems = prevModule.moduleData.items.map(item => {
                    if (item.name === itemName) {
                        return { ...item, format: newFormat };
                    }
                    return item;
                });
    
                return {
                    ...prevModule,
                    moduleData: {
                        ...prevModule.moduleData,
                        items: updatedItems
                    }
                };
            });
        }
    };

    function changeOrder(itemName, direction) {
        if(type == "event") {
            setEvent(prevEvent => {
                const items = [...prevEvent.eventData.items];
                const currentIndex = items.findIndex(item => item.name === itemName);
    
                if (currentIndex === -1) {
                    return;
                }
    
                let newIndex = currentIndex + (direction === 'up' ? -1 : 1);
    
                if (newIndex < 0 || newIndex >= items.length) {
                    return prevEvent;
                }
    
                // Výmena prvkov
                [items[currentIndex], items[newIndex]] = [items[newIndex], items[currentIndex]];
    
                return {
                    ...prevEvent,
                    eventData: {
                        ...prevEvent.eventData,
                        items: items
                    }
                };
            });
        }

        if(type == "module"){
            setModule(prevModule => {
                const items = [...prevModule.moduleData.items];
                const currentIndex = items.findIndex(item => item.name === itemName);
    
                if (currentIndex === -1) {
                    return;
                }
    
                let newIndex = currentIndex + (direction === 'up' ? -1 : 1);
    
    
                // Kontrola, či nie je nový index mimo rozsah
                if (newIndex < 0 || newIndex >= items.length) {
                    return prevModule;
                }
    
                // Výmena prvkov
                [items[currentIndex], items[newIndex]] = [items[newIndex], items[currentIndex]];
    
                return {
                    ...prevModule,
                    moduleData: {
                        ...prevModule.moduleData,
                        items: items
                    }
                };
            });
        }
    };




    return (
        <>

            <Popover>
                <Popover.Trigger>
                    <Chip
                        className="h-6 p-1"
                        iconOnly={<GenericSettings className="text-moon-16" />}
                        aria-label="Nastavenia"
                    />
                </Popover.Trigger>
                <Popover.Panel className="p-2 flex flex-col gap-1 w-[300px]">

                    <p className="text-moon-14 px-2 pt-2">Presunúť poradie:</p>
                    <div className="flex justify-between border-b-2 pb-2">
                        <MenuItem className="justify-start" onClick={(e) => changeOrder(item.name, "up")}>
                            <span className="flex w-6 h-6 bg-gohan items-center justify-center rounded">
                                <ArrowsChevronLeftDouble className="text-bulma text-moon-18" />
                            </span>
                            <p className="text-left">Vzad</p>
                        </MenuItem>

                        <MenuItem className="justify-end" onClick={(e) => changeOrder(item.name, "down")}>
                            <p className="text-right">Vpred</p>
                            <span className="flex w-6 h-6 bg-gohan items-center justify-center rounded">
                                <ArrowsChevronRightDouble className="text-bulma text-moon-18" />
                            </span>
                        </MenuItem>
                    </div>

                    <p className="text-moon-14 px-2 pt-2">Formát:</p>
                    <div className="flex justify-between border-b-2 pb-2">
                        <Radio className="px-2 flex justify-between w-full" value={item.format} onChange={(value) => changeFormat(item.name, value)}>
                            <Radio.Option value="1">
                                <Radio.Indicator />
                                Malý
                            </Radio.Option>
                            <Radio.Option value="2">
                                <Radio.Indicator />
                                Stredný
                            </Radio.Option>
                            <Radio.Option value="3">
                                <Radio.Indicator />
                                Veľký
                            </Radio.Option>
                        </Radio>
                    </div>

                    <p className="text-moon-14 px-2 pt-2">Názov údaju:</p>
                    <div className="flex justify-between  pb-2">
                        <Input
                            type="text"
                            defaultValue={item.name}
                            onChange={e => handleNameChange(e, item.name)}

                        />

                        <MenuItem className="justify-end bg-roshi-10 w-min ml-2" onClick={() => handleSaveName(item.name)}>
                            <p className="text-right">Uložiť</p>
                            <span className="flex w-6 h-6 bg-gohan items-center justify-center rounded">
                                <FilesSave className="text-bulma text-moon-18" />
                            </span>
                        </MenuItem>

                    </div>


                    <div className="flex justify-start pb-1">
                        <MenuItem className="justify-start bg-dodoria-10" onClick={(e) => handleDelete(item.name)}>
                            <span className="flex w-6 h-6 bg-gohan items-center justify-center rounded">
                                <GenericDelete className="text-bulma text-moon-18" />
                            </span>
                            <p className="text-left">Odstraniť údaj</p>
                        </MenuItem>
                    </div>
                </Popover.Panel>
            </Popover>

        </>
    )

}

export default DataSettings;