import React, {useCallback, useEffect, useState} from "react";
import { Button, Modal, IconButton, Form, InsetInput, Textarea, Snackbar } from '@heathmont/moon-core-tw';
import { ControlsCloseSmall, GenericAlarm, GenericCheckRounded } from '@heathmont/moon-icons-tw';

import axios from 'axios';


const EventForm = ({type, setData, closeModal, openModal, event, isModalOpen, setModalOpen, }) => {



  // Alerts 

  const [snackbar, setSnackbar] = useState("");

  const openSnackbarHandler = useCallback(
    (type) => {
      if (snackbar) {
        setSnackbar('');
        setTimeout(() => {
          setSnackbar(type);
        }, 400);
      } else {
        setSnackbar(type);
      }
    },
    [snackbar]
  );



  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // %%  Create new event modal config  %%%
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%


  const [formValue, setFormValue] = useState({
    "name": "",
    "place": "",
    "date": "",
    "dateTo": "",
    "time": "",
    "description": ""
  });


  useEffect(() => {
    if(type == "update"){
        setFormValue({
            "name": event?.name ? event.name : "",
            "place": event?.place ? event.place : "",
            "date": event?.date ? event.date : "",
            "dateTo": event?.dateTo ? event.dateTo : "",
            "time": event?.time ? event.time : "",
            "description": event?.description ? event.description : ""
          })
      }
    
  }, [event, type]);


  function handleSubmit(){
    
    

    let dataToSend = {
      "name": formValue.name,
      "place": formValue.place ? formValue.place : null,
      "date": formValue.date ? formValue.date : null,
      "dateTo": formValue.dateTo ? formValue.dateTo : null,
      "time": formValue.time ? formValue.time : null,
      "description": formValue.description ? formValue.description : null 
    }

    let apiUrl;

    if(type == "create"){
        apiUrl = "https://eventio.hasura.app/api/rest/event/create";
    } else if(type == "update"){
        apiUrl = `https://eventio.hasura.app/api/rest/event/update/${event.id}`;
    }



    axios.post(apiUrl, dataToSend)
      .then(response => {
        console.log("Odpoveď od servera:", response.data);

        let newData;
        let updatedData;

        if(type == "create"){
            newData = response.data?.insert_events_one ? response.data.insert_events_one : null;
        } else if(type == "update"){
            updatedData = response.data?.update_events_by_pk ? response.data.update_events_by_pk : null;
        }

        if(newData || updatedData){

            if(type == "create"){
                
              
                setData(prevData => {
                    return {
                    ...prevData,
                    events: [
                        ...prevData.events,
                        newData
                    ]
                    };
                });

            } else if(type == "update"){
                
                console.log(event);
                console.log(updatedData);
                setData(prevData => {
                  return {
                    ...updatedData,
                    eventData: prevData.eventData,
                    modules: prevData.modules
                  }
                });

            }
          

          setModalOpen(false);
          // reset form values
          setFormValue({
            "name": "",
            "place": "",
            "date": "",
            "dateTo": "",
            "time": "",
            "description": ""
          });
          openSnackbarHandler("uspesne-pridane");
        }
        


      })
      .catch(error => {
        console.error("Chyba pri odosielaní údajov na server:", error);
        
        setModalOpen(false);
        openSnackbarHandler("chyba-server");
      });

  }



  function handleInputChange(event){
    const {name, value} = event.target;
    setFormValue(prevFormValue => ({
      ...prevFormValue,
      [name]: value
    }));
  }

  


  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%





    return(
    
    <>



      {/*************************
          Create event modal 
      *************************/}

      <Modal open={isModalOpen} onClose={closeModal}>
        <Modal.Backdrop />
        <Modal.Panel className="max-w-md">

          <div className="border-b-[0.063rem] border-beerus pt-5 pb-4 px-6 relative">
            <h3 className="text-moon-18 text-bulma font-medium">{type == "create" ? "Nová udalosť" : "Upraviť udalosť"}</h3>
            <IconButton
              variant="ghost"
              size="sm"
              className="absolute top-4 end-5"
              onClick={closeModal}
            >
              <ControlsCloseSmall className="text-moon-24" />
            </IconButton>
          </div>

          <div className="w-full flex flex-col gap-3">

            <Form
              className="w-full p-5 flex flex-col gap-4"
              id="newEvent"
            >
            
              <Form.Item>
                <InsetInput placeholder="Zadajte názov udalosti" name="name" value={formValue.name} onChange={handleInputChange}>
                  <InsetInput.Label>Názov udalosti <i className="text-dodoria font-semibold">*</i></InsetInput.Label>
                </InsetInput>
              </Form.Item>

              <Form.Item>
                <InsetInput placeholder="Zadajte miesto konania udalosti" name="place" value={formValue.place} onChange={handleInputChange}>
                  <InsetInput.Label>Miesto konania</InsetInput.Label>
                </InsetInput>
              </Form.Item>

              <Form.Item>
                <InsetInput type="date" aria-label="date" name="date" value={formValue.date} onChange={handleInputChange}>
                  <InsetInput.Label>Dátum / Dátum od</InsetInput.Label>
                </InsetInput>
              </Form.Item>

              <Form.Item>
                <InsetInput type="date" aria-label="date" name="dateTo" value={formValue.dateTo} onChange={handleInputChange}>
                  <InsetInput.Label>Dátum do</InsetInput.Label>
                </InsetInput>
              </Form.Item>

              <Form.Item>
                <InsetInput type="time" aria-label="time" name="time" value={formValue.time} onChange={handleInputChange}>
                  <InsetInput.Label>Čas</InsetInput.Label>
                </InsetInput>
              </Form.Item>

              <Form.Item>
                <Textarea placeholder="Popis..." name="description" value={formValue.description} onChange={handleInputChange}/>
              </Form.Item>

            </Form>

          </div>

          <div className="flex gap-2 p-4 justify-end pt-2">
            <Button variant="outline" onClick={closeModal}>
              Zrušiť
            </Button>
            <Button disabled={formValue.name.trim() !== "" ? false : true} className="bg-eventio-primary" onClick={handleSubmit}>{type == "create" ? "Vytvoriť" : "Upraviť"}</Button>
          </div>


        </Modal.Panel>

      </Modal>


        {/* Alerts */}

        <Snackbar isOpen={snackbar === 'uspesne-pridane'} onOpenChange={setSnackbar} position="bottom-right">
            <Snackbar.Icon>
                <GenericCheckRounded className="text-roshi" />
            </Snackbar.Icon>
            <Snackbar.Message>Udalosť bola úspešne {type == "create" ? "pridaná" : "upravená"}</Snackbar.Message>
        </Snackbar>
        
        <Snackbar isOpen={snackbar === 'chyba-server'} onOpenChange={setSnackbar} position="bottom-right">
            <Snackbar.Icon>
                <GenericAlarm className="text-dodoria" />
            </Snackbar.Icon>
            <Snackbar.Message>Pri nahrávani udalosti na server nastala chyba!</Snackbar.Message>
            <Snackbar.Message>Skúste to prosím neskôr</Snackbar.Message>
        </Snackbar>
      
        
        </>
    )

}

export default EventForm