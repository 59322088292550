import React, { Fragment, useContext, useEffect, useState } from "react";
import { Loader, Alert, Button } from '@heathmont/moon-core-tw';
import loading from "../assets/loading.svg";

import { AuthContext } from "../providers/profileProvider";
import EventForm from "../components/EventForm";
// import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';


const NotFound = () => {

  const { token } = useContext(AuthContext);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;



  return(
    <Fragment>
    
      <div className="flex flex-col justify-center items-center mb-8 mt-14">
        <div className="spinner mb-8">
            <img src={loading} alt="Loading" />
        </div>
        <h3 className="text-[32px] text-eventio-primary font-medium transition-colors font-['Josefin_Sans']">Prebieha načítavanie alebo sa stránka nenašla</h3>
      </div>

    
    </Fragment>
  )
 
};

export default NotFound;
