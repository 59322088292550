import React, { Fragment, useContext, useEffect, useState, useCallback } from "react";
import { Loader, Alert, IconButton, Button, Snackbar } from '@heathmont/moon-core-tw';
import { TextCards, TextTable, TextTableAlternative, ControlsChevronRight, MapsMarker, TimeCalendarDate, TimeClock, GenericAlarmRound, GenericInfo, GenericPlus, FilesSave, GenericEdit, GenericCheckRounded, GenericAlarm } from '@heathmont/moon-icons-tw';
import { AuthContext } from "../providers/profileProvider";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import EventSettings from "../components/EventSettings";
import DataInput from "../components/DataInput";
import DataComponent from "../components/DataComponent";
import DataSettings from "../components/DataSettings";
import DataForm from "../components/DataForm";
import ModuleForm from "../components/ModuleForm";


const EventDetail = () => {
    const { eventId } = useParams();
    const { token, user } = useContext(AuthContext);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const [windowSize, setWindowSize] = useState('sm');

    useEffect(() => {
      const checkSize = () => {
        if (window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches) {
            setWindowSize('md');
        } else if (window.matchMedia('(min-width: 1024px)').matches) {
            setWindowSize('lg');
        } else {
            setWindowSize('sm');
        }
      };
      checkSize();
      window.addEventListener('resize', checkSize);
      return () => window.removeEventListener('resize', checkSize);
    }, []);
  


    const navigate = useNavigate();

    function handleRedirect(moduleId) {
        navigate(`/module/${moduleId}`);
    }

    const [event, setEvent] = useState();
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {

        if (token?.length > 0) {


            axios.get(`https://eventio.hasura.app/api/rest/event/${eventId}`)
                .then(res => {
                    setIsLoading(false);
                    let resEvent = res.data?.events_by_pk;
                    if (resEvent) {
                        setEvent(resEvent);
                    }
                }).catch(error => {

                    console.error("Chyba pri načítaní údajov:", error)
                    setIsLoading(false);

                });

        }

    }, [token, eventId]);

    function formatDate(inputDate) {
        const parts = inputDate.split("-");
        const europeanDate = `${parts[2]}.${parts[1]}.${parts[0]}`;
        return europeanDate;
    }


    // ===============================
    //            Edit Mode
    // ===============================

    const [snackbar, setSnackbar] = useState("");

    const openSnackbarHandler = useCallback(
        (type) => {
            if (snackbar) {
                setSnackbar('');
                setTimeout(() => {
                    setSnackbar(type);
                }, 400);
            } else {
                setSnackbar(type);
            }
        },
        [snackbar]
    );

    const [editMode, setEditMode] = useState(false);

    const closeEditMode = () => setEditMode(false);
    const openEditMode = () => setEditMode(true);

    const [dataModal, setDataModal] = useState(false);
    const openDataModal = () => setDataModal(true);
    const closeDataModal = () => setDataModal(false);

    function handleUpdate() {

        const dataToSend = {
            eventData: event.eventData
        }

        axios.post(`https://eventio.hasura.app/api/rest/event/update/eventdata/${event.id}`, dataToSend)
            .then(response => {
                if (response.data) {
                    openSnackbarHandler("uspesne-aktualizovane");
                    setEditMode(false);
                }
            })
            .catch(error => {
                console.error("Chyba pri odosielaní údajov na server:", error);
                openSnackbarHandler("chyba-server");
            });

    }


    function handleChange(itemName, newValue) {
        setEvent(prevEvent => {
            const updatedItems = prevEvent.eventData.items.map(item => {
                if (item.name === itemName) {
                    return { ...item, value: newValue };
                }
                return item;
            });

            return {
                ...prevEvent,
                eventData: {
                    ...prevEvent.eventData,
                    items: updatedItems
                }
            };
        });
    };


    // ===============================

    return (
        <Fragment>

            {

                isLoading ? (
                    <Loader />
                ) : (
                    <>

                        <div className="flex justify-between items-center mb-8">
                            <div>
                                <h3 className="text-moon-24 lg:text-moon-32 font-medium transition-colors mr-6">{event?.name ? event.name : "Udalosť nenájdená"}</h3>
                                {event?.name && event?.userEvents?.email != user?.email && <p className="text-zeno">Zdieľaná udalosť</p>}
                            </div>
                            <EventSettings
                                user={user}
                                event={event}
                                setEvent={setEvent}
                                editMode={editMode}
                                openEditMode={openEditMode}
                                closeEditMode={closeEditMode}
                            />
                        </div>

                        {console.log(event)}

                        {event ? (
                            <>

                                {/* Základne informácie */}

                                <div className="event-item bg-goku w-full flex flex-col md:flex-row justify-start items-start md:items-center rounded-2xl mb-8 py-6 px-6">

                                    <div className="mr-8 md:flex-none *:mb-2 *:md:mb-0 *:text-moon-14 *:md:text-moon-16">
                                        {event.place && (<p className="flex flex-row items-center"><MapsMarker className="text-moon-32 mr-2 flex-none" />{event.place}</p>)}
                                        {event.date && (<p className="flex flex-row items-center"><TimeCalendarDate className="text-moon-32 mr-2 flex-none" />{formatDate(event.date)}  {event.dateTo ? ` - ${formatDate(event.dateTo)}` : ""}</p>)}
                                        {event.time && (<p className="flex flex-row items-center"><TimeClock className="text-moon-32 mr-2 flex-none" />{event.time.slice(0, 5)}</p>)}
                                    </div>


                                    {event.description && (
                                        <div className="pt-4 w-full border-t-2 md:pt-0 md:border-t-0 md:mt-0 md:pl-8 md:border-l-2 md:mr-8">
                                            <p className="text-zeno text-moon-14 m-0">Popis:</p>
                                            <p>{event.description}</p>
                                        </div>
                                    )}

                                </div>



                                {/* Data udalosti */}



                                <div className="flex justify-between items-center mb-4">
                                    <h4 className="text-moon-20 md:text-moon-20 lg:text-moon-24 font-medium transition-colors">Údaje udalosti:</h4>

                                    {editMode && (
                                        <div className="ml-4 flex justify-end">
                                            <IconButton className="bg-eventio-primary" icon={<GenericPlus />} onClick={openDataModal} />
                                            <Button onClick={handleUpdate} className="ml-2 bg-roshi-60" iconLeft={<FilesSave />}>Uložiť</Button>
                                        </div>
                                    ) || (
                                            <IconButton className="bg-gohan" onClick={openEditMode} icon={<GenericEdit className="text-popo" />} />
                                        )}

                                </div>

                                {/* Formulár na pridanie nového údaju */}

                                <DataForm type="event" setEvent={setEvent} closeDataModal={closeDataModal} dataModal={dataModal} />


                                {/* Všetky údaje */}

                                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">

                                    {event.eventData?.items?.length > 0 ? (
                                        event.eventData?.items?.map((item) => (
                                            <div style={{ gridColumn: `span ${windowSize == "sm" ? (item.format == "1" ? "1" : "2") : item.format}` }} className={`bg-goku rounded-2xl py-3 px-4 md:py-6 md:px-6`} key={item.name}>

                                                <div className="flex justify-between">

                                                    <p className="text-zeno text-moon-14 m-0">{item.name}:</p>

                                                    {editMode && (
                                                        <DataSettings type="event" event={event} setEvent={setEvent} item={item}/>
                                                    )}

                                                </div>


                                                {/* Vykreslenie konkrétnych hodnôt / inputov */}

                                                {editMode && (

                                                    <DataInput item={item} handleChange={handleChange} />

                                                ) || (

                                                    <DataComponent item={item} formatDate={formatDate} />

                                                )}

                                            </div>
                                        ))
                                    ) : (
                                        <Alert className="bg-beerus col-span-6">
                                            <Alert.Message>
                                                <GenericInfo className="mr-1" />
                                                Pri tejto udalosti nemáte pridané žiadné doplňujúce údaje.
                                            </Alert.Message>
                                        </Alert>
                                    )}

                                </div>


                                <div className="flex justify-between items-center mb-4 mt-8">
                                    <h4 className="text-moon-20 md:text-moon-20 lg:text-moon-24 font-medium transition-colors">Moduly udalosti:</h4>
                                    <ModuleForm setEvent={setEvent} eventId={event.id} openSnackbarHandler={openSnackbarHandler}/>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

                                        {event.modules?.length > 0 ? (
                                            event.modules.map((module) => (
                                                <div key={"module-" + module.id} className={`col-span-1 bg-eventio-bg-blue rounded-2xl py-6 px-3 flex flex-row justify-between items-center cursor-pointer transition-shadow shadow-none hover:shadow-md`} onClick={() => handleRedirect(module.id)}>
                                                    <div className="flex flex-row justify-start items-center">  

                                                        { module.type == "table" && <TextTable className="text-moon-32 font-semibold text-eventio-primary mr-2" /> }
                                                        { module.type == "data" && <TextCards className="text-moon-32 font-semibold text-eventio-primary mr-2" /> }
                                                        { module.type == "dataTable" && <TextTableAlternative className="text-moon-32 font-semibold text-eventio-primary mr-2" /> }

                                                        <div>
                                                            <p className="text-moon-14 font-semibold m-0">{ module.name }</p>
                                                            { module.description && <p className="text-moon-14 m-0">{ module.description }</p> }
                                                        </div>
                                                    </div>
                                                    <ControlsChevronRight className="text-zeno text-moon-24" />
                                                </div>
                                            ))
                                        ) : (
                                            <Alert className="bg-beerus col-span-6">
                                                <Alert.Message>
                                                    <GenericInfo className="mr-1" />
                                                    K tejto udalosti nemáte pripojené žiadne moduly.
                                                </Alert.Message>
                                            </Alert>
                                        )}

                                </div>




                            </>
                        ) : (
                            <Alert className="bg-dodoria-10">
                                <Alert.Message>
                                    <GenericAlarmRound className="text-moon-24" />
                                    Udalosť nebola nájdena alebo k nej nemáte prístupové práva.
                                </Alert.Message>
                            </Alert>
                        )}
                    </>
                )

            }

            {/* Alerts */}

            <Snackbar isOpen={snackbar === 'uspesne-aktualizovane'} onOpenChange={setSnackbar} position="bottom-right">
                <Snackbar.Icon>
                    <GenericCheckRounded className="text-roshi" />
                </Snackbar.Icon>
                <Snackbar.Message>Údaje boli úspešne uložené</Snackbar.Message>
            </Snackbar>

            <Snackbar isOpen={snackbar === 'modul-pridany'} onOpenChange={setSnackbar} position="bottom-right">
                <Snackbar.Icon>
                    <GenericCheckRounded className="text-roshi" />
                </Snackbar.Icon>
                <Snackbar.Message>Modul bol úspešne vytvorený</Snackbar.Message>
            </Snackbar>

            <Snackbar isOpen={snackbar === 'chyba-server'} onOpenChange={setSnackbar} position="bottom-right">
                <Snackbar.Icon>
                    <GenericAlarm className="text-dodoria" />
                </Snackbar.Icon>
                <Snackbar.Message>Pri nahrávani údajov na server nastala chyba!</Snackbar.Message>
                <Snackbar.Message>Skúste to prosím neskôr</Snackbar.Message>
            </Snackbar>


        </Fragment>
    )

};

export default EventDetail;
