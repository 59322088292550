import React, { useState } from "react";
import { IconButton, Button, Input, Textarea, MenuItem, Radio, Form, Modal, InsetInput, Dropdown } from '@heathmont/moon-core-tw';
import { ControlsCloseSmall } from '@heathmont/moon-icons-tw';


const DataForm = ({type, setEvent, setModule, closeDataModal, dataModal}) => {



    const dataTypes = [
        { value: "shortText", name: "Krátky text" },
        { value: "longText", name: "Dlhý text" },
        { value: "number", name: "Číslo" },
        { value: "date", name: "Dátum" },
        { value: "url", name: "URL Link" },
        { value: "currencyEur", name: "Suma €" },
        { value: "currencyCzk", name: "Suma Kč" }
    ];

    const [dataForm, setDataForm] = useState({
        "name": "",
        "type": "Krátky text",
        "value": "",
        "format": "2"
    });

    function handleInputChange(event) {
        const { name, value } = event.target;
        setDataForm(prevDataForm => ({
            ...prevDataForm,
            [name]: value
        }));
    }


    function handleAddItem() {
        const newItem = {
            "name": dataForm.name ? dataForm.name : "",
            "type": dataForm.type ? dataTypes.find((item) => item.name == dataForm.type).value : "",
            "value": dataForm.value ? dataForm.value : "",
            "format": dataForm.format ? dataForm.format : ""
        };

        if(type == "event"){
            setEvent(prevEvent => {
                const currentEventData = prevEvent.eventData || { items: [] };
                let updatedItems = [...currentEventData.items, newItem];
        
                return {
                    ...prevEvent,
                    eventData: {
                        ...currentEventData,
                        items: updatedItems
                    }
                };
            });
        
        }
        
        if(type == "module"){

            setModule(prevModule => {
                const currentModuleData = prevModule.moduleData || { items: [] };
                let updatedItems = [...currentModuleData.items, newItem];

                return {
                    ...prevModule,
                    moduleData: {
                        ...prevModule.moduleData,
                        items: updatedItems
                    }
                };
            });
        }

        closeDataModal();
        setDataForm({
            "name": "",
            "type": "Krátky text",
            "value": "",
            "format": "2"
        });
    }


    return (
        <>


            {/* Pridanie nového údaju */}


            <Modal open={dataModal} onClose={closeDataModal}>
                <Modal.Backdrop />
                <Modal.Panel className="max-w-md">

                    <div className="border-b-[0.063rem] border-beerus pt-5 pb-4 px-6 relative">
                        <h3 className="text-moon-18 text-bulma font-medium">Nový údaj</h3>
                        <IconButton
                            variant="ghost"
                            size="sm"
                            className="absolute top-4 end-5"
                            onClick={closeDataModal}
                        >
                            <ControlsCloseSmall className="text-moon-24" />
                        </IconButton>
                    </div>

                    <div className="w-full flex flex-col gap-3">

                        <Form
                            className="w-full p-5 flex flex-col gap-4"
                            id="newDataItem"
                        >

                            <Form.Item>
                                <InsetInput placeholder="Zadajte názov udalosti" name="name" value={dataForm.name} onChange={handleInputChange}>
                                    <InsetInput.Label>Názov údaju <i className="text-dodoria font-semibold">*</i></InsetInput.Label>
                                </InsetInput>
                            </Form.Item>




                            <p className="text-moon-14 px-2 pt-2">Dátovy typ:</p>
                            {/* <Form.Item className="relative"> */}
                            <Dropdown value={dataForm.type} onChange={(value) => handleInputChange({ target: { name: "type", value: value } })} size="lg">
                                {({ open }) => (
                                    <>
                                        <Dropdown.Select
                                            open={open}
                                            placeholder="Dátovy typ"
                                        >
                                            {dataForm?.type}
                                        </Dropdown.Select>

                                        <Dropdown.Options>
                                            {dataTypes.map((dataType) => (
                                                <Dropdown.Option value={dataType.name} key={dataType.name}>
                                                    {({ selected, active }) => (
                                                        <MenuItem isActive={active} isSelected={selected}>
                                                            <MenuItem.Title>{dataType.name}</MenuItem.Title>
                                                            <MenuItem.Radio isSelected={selected} />
                                                        </MenuItem>
                                                    )}
                                                </Dropdown.Option>
                                            ))}
                                        </Dropdown.Options>
                                    </>
                                )}
                            </Dropdown>


                            {/* </Form.Item> */}



                            <p className="text-moon-14 px-2 pt-2">Formát:</p>

                            <Form.Item>
                                <Radio className="px-2 flex justify-between w-full" name="format" value={dataForm.format} onChange={(value) => handleInputChange({ target: { name: "format", value: value } })}>
                                    <Radio.Option value="1">
                                        <Radio.Indicator />
                                        1 / 6
                                    </Radio.Option>
                                    <Radio.Option value="2">
                                        <Radio.Indicator />
                                        1 / 3
                                    </Radio.Option>
                                    <Radio.Option value="3">
                                        <Radio.Indicator />
                                        1 / 2
                                    </Radio.Option>
                                </Radio>
                            </Form.Item>

                            <p className="text-moon-14 px-2 pt-2">Hodnota:</p>

                            {dataForm.type == "Krátky text" && (
                                <Input
                                    type="text"
                                    value={dataForm.value} onChange={(e) => handleInputChange({ target: { name: "value", value: e.target.value } })}
                                    className="text-moon-16 font-semibold m-0 mt-2"
                                />
                            )}

                            {dataForm.type == "Dlhý text" && (
                                <Textarea
                                    value={dataForm.value} onChange={(e) => handleInputChange({ target: { name: "value", value: e.target.value } })}
                                    className="text-moon-16 font-semibold m-0 mt-2"
                                />
                            )}

                            {(dataForm.type == "Číslo" || dataForm.type == "Suma €" || dataForm.type == "Suma Kč") && (
                                <Input
                                    type="number"
                                    value={dataForm.value} onChange={(e) => handleInputChange({ target: { name: "value", value: e.target.value } })}
                                    className="text-moon-16 font-semibold m-0 mt-2 outline-4"
                                />
                            )}

                            {dataForm.type == "Dátum" && (
                                <InsetInput
                                    type="date"
                                    value={dataForm.value} onChange={(e) => handleInputChange({ target: { name: "value", value: e.target.value } })}
                                    className="text-moon-16 font-semibold m-0 mt-2"
                                />
                            )}

                            {dataForm.type == "URL Link" && (
                                <Input
                                    type="url"
                                    value={dataForm.value} onChange={(e) => handleInputChange({ target: { name: "value", value: e.target.value } })}
                                    className="text-moon-16 font-semibold m-0 mt-2"
                                />
                            )}


                        </Form>

                    </div>


                    <div className="flex gap-2 p-4 justify-end pt-2">
                        <Button variant="outline" onClick={closeDataModal}>
                            Zrušiť
                        </Button>
                        <Button disabled={dataForm.name.trim() !== "" ? false : true} className="bg-eventio-primary" onClick={handleAddItem}>Pridať údaj</Button>
                    </div>


                </Modal.Panel>

            </Modal>




        </>
    )

}

export default DataForm