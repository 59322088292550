import React, { useState, useEffect } from 'react';
import { Checkbox, Label, Input, Button, Tooltip, Chip, Modal, Form, InsetInput, IconButton, Alert } from "@heathmont/moon-core-tw";
import { GenericSettings, GenericPlus, ControlsCloseSmall, GenericDelete, ArrowsChevronDownDouble, ArrowsChevronUpDouble, GenericEdit, FilesSave, GenericInfo } from "@heathmont/moon-icons-tw";
import { Table } from '@heathmont/moon-table-tw';
import axios from 'axios'

const ModuleTable = ({ moduleId, module, moduleColumns, moduleData, setModule, openSnackbarHandler }) => {

    const [editMode, setEditMode] = useState(false);

    const closeEditMode = () => setEditMode(false);
    const openEditMode = () => setEditMode(true);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const closeModal = () => setIsOpen(false);

    const openModalWithItem = (index) => {
        const itemData = { ...moduleData[index], index };
        setSelectedItem(itemData);
        setIsOpen(true);
    };

    


    const actionItems = (index) => (
        editMode && (
            <div className='flex flex-row items-center *:flex-none min-w-min'>
                <Tooltip>
                    <Tooltip.Trigger className="min-w-4 h-8">
                        <Chip
                            className='py-1 px-1'
                            variant="ghost"
                            iconOnly={<GenericEdit className="text-moon-20" />}
                            onClick={() => openModalWithItem(index)}
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content position="top-start">
                        Upraviť záznam
                        <Tooltip.Arrow />
                    </Tooltip.Content>
                </Tooltip>
                <Tooltip>
                    <Tooltip.Trigger className="min-w-4 h-8">
                        <Chip
                            className='py-1 px-1'
                            variant="ghost"
                            iconOnly={<ArrowsChevronDownDouble className="text-moon-14" />}
                            onClick={() => handleMoveDown(index)}
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content position="top-start">
                        Presunúť nižšie
                        <Tooltip.Arrow />
                    </Tooltip.Content>
                </Tooltip>

                <Tooltip>
                    <Tooltip.Trigger className="min-w-4 h-8">
                        <Chip
                            className='py-1 px-1'
                            variant="ghost"
                            iconOnly={<ArrowsChevronUpDouble className="text-moon-14" />}
                            onClick={() => handleMoveUp(index)}
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content position="top-start">
                        Presunúť vyššie
                        <Tooltip.Arrow />
                    </Tooltip.Content>
                </Tooltip>

                <Tooltip>
                    <Tooltip.Trigger className="min-w-4 h-8">
                        <Chip
                            className='py-1 px-1'
                            variant="ghost"
                            iconOnly={<GenericDelete className="text-moon-20" />}
                            onClick={() => handleDelete(index)}
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content position="top-start">
                        Odstrániť záznam
                        <Tooltip.Arrow />
                    </Tooltip.Content>
                </Tooltip>
            </div>
        )
    );



    const handleDelete = (indexToDelete) => {
        setModule(prevModule => {
            const updatedItems = prevModule.records.items.filter((item, index) => index !== indexToDelete);
            return {
                ...prevModule,
                records: {
                    ...prevModule.records,
                    items: updatedItems,
                },
            };
        });
    };

    const handleMoveUp = (index) => {
        setModule(prevModule => {
            let newItems = [...prevModule.records.items];
            if (index > 0) {
                [newItems[index], newItems[index - 1]] = [newItems[index - 1], newItems[index]];
            }
            return {
                ...prevModule,
                records: {
                    ...prevModule.records,
                    items: newItems,
                },
            };
        });
    };

    const handleMoveDown = (index) => {
        setModule(prevModule => {
            let newItems = [...prevModule.records.items];
            if (index < newItems.length - 1) {
                [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]];
            }
            return {
                ...prevModule,
                records: {
                    ...prevModule.records,
                    items: newItems,
                },
            };
        });
    };


    const mapColumns = moduleColumns?.map(column => ({
        Header: column.name,
        accessor: column.slug
    }));

    const tableColumns = Array.isArray(mapColumns) ? [
        ...mapColumns,
        {
            Header: editMode ? 'Akcie' : '',
            accessor: 'actions',
            width: editMode ? 100 : 10,
        },
    ] : [];


    const tableData = moduleData?.map((data, index) => {
        let newData = {};
        for (const column of moduleColumns) {
            newData[column.slug] = data[column.slug];
        }
        newData = {
            ...newData,
            actions: actionItems(index)
        };
        return newData;
    }) || [];


    const [formData, setFormData] = useState({});

    

    useEffect(() => {
        const initialState = moduleColumns?.reduce((acc, column) => {
            acc[column.slug] = '';
            return acc;
        }, {});
        setFormData(initialState);
        
    }, [moduleColumns]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const isFormValid = () => {
        return moduleColumns?.every(column => {
            return !column.required || (column.required && formData[column.slug]);
        });
    };

    const isEditFormValid = () => {
        return moduleColumns?.every(column => {
            return !column.required || (column.required && selectedItem[column.slug] && selectedItem[column.slug].trim() !== '');
        });
    };
    


    const handleSubmit = () => {
        const newItem = moduleColumns.reduce((acc, column) => {
            acc[column.slug] = formData[column.slug] === '' && !column.required ? null : formData[column.slug];
            return acc;
        }, {});

        setModule(prevModule => {
            const records = prevModule.records || {};
            const items = records.items || [];

            const updatedItems = [...items, newItem];

            return {
                ...prevModule,
                records: {
                    ...records,
                    items: updatedItems,
                },
            };
        });

        const resetState = moduleColumns.reduce((acc, column) => {
            acc[column.slug] = column.required ? '' : column.default || null;
            return acc;
        }, {});

        setFormData(resetState);
    };




    const handleChange = (e) => {
        e.preventDefault();
        const { index } = selectedItem;
        if (index !== undefined) {
            setModule(prevModule => {
                const updatedItems = [...prevModule.records.items];
                updatedItems[index] = { ...updatedItems[index], ...selectedItem };
                
                delete updatedItems[index].index;
                return {
                    ...prevModule,
                    records: {
                        ...prevModule.records,
                        items: updatedItems,
                    },
                };
            });
        } else {
            setModule(prevModule => {
                const newItem = { ...selectedItem };
                delete newItem.index
                return {
                    ...prevModule,
                    records: {
                        ...prevModule.records,
                        items: [...prevModule.records.items, newItem],
                    },
                };
            });
        }
        closeModal();
    };


    // ===================
    //   Table structure
    // ===================

    const [formColumn, setFormColumn] = useState({
        name: "",
        required: false
    });
    const [structureOpen, setStructureOpen] = useState(false);
    const closeStructureModal = () => setStructureOpen(false);
    const openStructureModal = () => setStructureOpen(true);
    

    function makeSlug(str) {
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        str = str.toLowerCase().replace(/[^a-z0-9\s]/g, '');
        return str.split(' ').map((word, index) => {
          return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
        }).join('');
      }
      

      const handleAddColumn = (e) => {
        e.preventDefault();
        const newColumn = {
            name: formColumn.name,
            slug: makeSlug(formColumn.name),
            type: "string",
            default: null,
            required: formColumn.required
        };
    
        console.log(newColumn);
    
        setModule(prevModule => ({
          ...prevModule,
          tableSchema: {
            ...prevModule.tableSchema || { columns: [] }, 
            columns: [
                ...(prevModule.tableSchema?.columns || []), 
                newColumn
            ]
          },
          records: {
            ...prevModule.records || { items: [] }, 
            items: (prevModule.records?.items || []).map(item => ({
              ...item,
              [newColumn.slug]: newColumn.default 
            }))
          }
        }));
    
        
        setFormColumn({name: '', required: false});
    };
    
    
    
      

    const handleMoveColumnUp = (index) => {
        setModule(prevModule => {
          const newColumns = [...prevModule.tableSchema.columns];
          if (index > 0) {
            [newColumns[index - 1], newColumns[index]] = [newColumns[index], newColumns[index - 1]];
          }
          return {
            ...prevModule,
            tableSchema: {
              ...prevModule.tableSchema,
              columns: newColumns
            }
          };
        });
    };

    const handleMoveColumnDown = (index) => {
        setModule(prevModule => {
          const newColumns = [...prevModule.tableSchema.columns];
          if (index < newColumns.length - 1) {
            [newColumns[index + 1], newColumns[index]] = [newColumns[index], newColumns[index + 1]];
          }
          return {
            ...prevModule,
            tableSchema: {
              ...prevModule.tableSchema,
              columns: newColumns
            }
          };
        });
    };

    const handleColumnDelete = (index) => {
        setModule(prevModule => ({
          ...prevModule,
          tableSchema: {
            ...prevModule.tableSchema,
            columns: prevModule.tableSchema.columns.filter((_, i) => i !== index)
          }
        }));
    };
    


    function handleUpdate() {

        const dataToSend = {
            tableSchema: module.tableSchema,
            records: module.records
        }

        axios.post(`https://eventio.hasura.app/api/rest/module/update/moduletable/${moduleId}`, dataToSend)
            .then(response => {
                if (response.data) {
                    openSnackbarHandler("uspesne-aktualizovane");
                    setEditMode(false);
                }
            })
            .catch(error => {
                console.error("Chyba pri odosielaní údajov na server:", error);
                openSnackbarHandler("chyba-server");
            });

    }


    return (
        <>

            <div className="flex justify-between items-center mb-4 mt-8">
                <h4 className="text-moon-20 md:text-moon-20 lg:text-moon-24 font-medium transition-colors">Tabuľka:</h4>

                {editMode && (
                    <>
                        <div className="ml-4 justify-end hidden md:flex">
                            <Button onClick={openStructureModal} className="ml-2 bg-nappa-60" iconLeft={<GenericSettings />}>Štruktúra tabuľky</Button>
                            <Button onClick={handleUpdate} className="ml-2 bg-roshi-60" iconLeft={<FilesSave />}>Uložiť</Button>
                        </div>

                        <div className="ml-4 justify-end flex md:hidden">
                            <IconButton onClick={openStructureModal} className="ml-2 bg-nappa-60" icon={<GenericSettings />} />
                            <IconButton onClick={handleUpdate} className="ml-2 bg-roshi-60" icon={<FilesSave />} />
                        </div>
                    </>
                ) || (
                        <IconButton className="bg-gohan" onClick={openEditMode} icon={<GenericEdit className="text-popo" />} />
                    )}

            </div>


            <div className="bg-goku w-full rounded-2xl mb-8 py-4 px-4 overflow-x-auto">
                {moduleColumns && !structureOpen ? (
                    <Table columns={tableColumns} data={tableData} isCellBorder className='rounded' />
                ) : (
                    <Alert className="bg-dodoria-10 col-span-6">
                        <Alert.Message>
                            <GenericInfo className="mr-1 mt-1" />
                            Tabuľka nebola doposiaľ inicializovaná. Prosím definujte schému tabuľky v editačnom režime.
                        </Alert.Message>
                    </Alert>
                )}
            </div>

            {editMode && !structureOpen && (
                <div className="bg-goku w-full rounded-2xl mb-8 py-4 px-4 overflow-x-auto">
                    <div className='flex flex-row flex-nowrap w-full'>
                        {moduleColumns?.map(column => (
                            <div className='mr-2 min-w-48' key={column.slug}>
                                <Label className='text-moon-14 ml-2 p-0' htmlFor={column.slug}>{column.name}: {column.required && <i className='text-dodoria'>*</i>}</Label>
                                <Input
                                    type="text"
                                    id={column.slug}
                                    value={formData[column.slug] || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        ))}

                        <Button
                            className='bg-eventio-primary mt-6 shrink-0'
                            iconLeft={<GenericPlus className="mr-2" />}
                            disabled={!isFormValid()}
                            onClick={handleSubmit} 
                        >
                            Pridať
                        </Button>

                    </div>
                </div>

            )}

            {/* Table structure modal */}
            <Modal open={structureOpen} onClose={closeStructureModal}>
                <Modal.Backdrop />
                <Modal.Panel className="max-w-md">
                    <div className="p-4 border-b-2 border-beerus">
                        <h3 className="text-moon-18 text-bulma font-medium">Štruktúra tabuľky</h3>
                        <IconButton
                            variant="ghost"
                            size="sm"
                            className="absolute top-4 end-5"
                            onClick={closeStructureModal}
                        >
                            <ControlsCloseSmall className="text-moon-24" />
                        </IconButton>
                    </div>
                    <div className="p-4 ">
                        <form onSubmit={handleAddColumn} className='flex flex-row justify-between items-center'>
                            <Form.Item>
                                <InsetInput
                                    value={formColumn.name}
                                    name='name'
                                    onChange={(e) => setFormColumn({ ...formColumn, name: e.target.value })}
                                >
                                    <InsetInput.Label>Názov stĺpca</InsetInput.Label>
                                </InsetInput>
                            </Form.Item>

                            <Form.Item className='w-min mr-3 ml-2'>
                                <Checkbox
                                    value={formColumn.required}
                                    checked={formColumn.required}
                                    label="Povinné" id="required"
                                    name='required'
                                    onChange={(e) => setFormColumn({...formColumn, required: e.target.checked})}
                                />
                            </Form.Item>

                            <Button type="submit" className='bg-eventio-primary w-min'>Pridať</Button>
                        </form>
                    </div>
                    <div className="p-4 border-t-2 border-beerus">
                        <h4 className="text-moon-16 text-bulma font-medium mb-2">Stĺpce:</h4>

                        { moduleColumns ? moduleColumns.map((data, index) => {
                            return(
                        <div className="bg-eventio-bg-light w-full flex justify-between items-center rounded-lg mb-2 py-3 px-3" key={data.slug}>
                            <div>
                                <p className="text-moon-14 font-semibold m-0">{data.name} {data.required && <i className='text-dodoria'>*</i>}</p>
                            </div>
                            <div className='flex flex-row items-center'>
                                <Tooltip>
                                    <Tooltip.Trigger className="min-w-4 h-8">
                                        <Chip
                                            className='py-1 px-1'
                                            variant="ghost"
                                            iconOnly={<ArrowsChevronDownDouble className="text-moon-14" />}
                                            onClick={() => handleMoveColumnDown(index)}
                                        />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content position="top-start">
                                        Presunúť vpravo
                                        <Tooltip.Arrow />
                                    </Tooltip.Content>
                                </Tooltip>

                                <Tooltip>
                                    <Tooltip.Trigger className="min-w-4 h-8">
                                        <Chip
                                            className='py-1 px-1'
                                            variant="ghost"
                                            iconOnly={<ArrowsChevronUpDouble className="text-moon-14" />}
                                            onClick={() => handleMoveColumnUp(index)}
                                        />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content position="top-start">
                                        Presunúť vľavo
                                        <Tooltip.Arrow />
                                    </Tooltip.Content>
                                </Tooltip>

                                <Tooltip>
                                    <Tooltip.Trigger className="min-w-4 h-8">
                                        <Chip
                                            className='py-1 px-1' 
                                            variant="ghost"
                                            iconOnly={<GenericDelete className="text-moon-20" />}
                                            onClick={() => handleColumnDelete(index)}
                                        />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content position="top-start">
                                        Odstrániť stĺpec
                                        <Tooltip.Arrow />
                                    </Tooltip.Content>
                                </Tooltip>
                            </div>
                        </div>
                    )}) : (
                        <Alert className="bg-dodoria-10 col-span-6">
                            <Alert.Message>
                                <GenericInfo className="mr-1 mt-1" />
                                Vaša tabuľka zatiaľ nemá žiadné stĺpce.
                            </Alert.Message>
                        </Alert>
                    )
                    } 

                    </div>
                </Modal.Panel>
            </Modal>

            {/* Edit item modal */}
            <Modal open={isOpen} onClose={closeModal}>
                <Modal.Backdrop />
                <Modal.Panel>
                    <div className="p-4 border-b-2 border-beerus">
                        <h3 className="text-moon-18 text-bulma font-medium">Upraviť záznam</h3>
                    </div>
                    <div className="p-4">
                        <form onSubmit={handleChange}>
                            {moduleColumns?.map((column) => (
                                <Form.Item key={column.slug}>
                                    <InsetInput
                                        className='mb-3'
                                        defaultValue={selectedItem ? selectedItem[column.slug] : ''}
                                        onChange={(e) => setSelectedItem({ ...selectedItem, [column.slug]: e.target.value })}
                                    >
                                        <InsetInput.Label>{column.name} {column.required && <i className='text-dodoria font-bold'>*</i>}</InsetInput.Label>
                                    </InsetInput>
                                </Form.Item>
                            ))}

                            <div className="flex gap-2 justify-end pt-2">
                                <Button variant="outline" onClick={closeModal}>
                                    Zrušiť
                                </Button>
                                {isOpen && <Button type="submit" className='bg-eventio-primary' disabled={!isEditFormValid()}>Uložiť zmeny</Button>}
                            </div>

                            
                        </form>
                    </div>
                </Modal.Panel>
            </Modal>


        </>
    );
};

export default ModuleTable;
