
import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';




export const AuthContext = createContext();

export const ProfileProvider = ({ children }) => {

  const { getAccessTokenSilently, isAuthenticated, user, loginWithPopup, logout} = useAuth0();
  const [token, setToken] = useState(null);

  // Login function

  const handleLogin = async () => {
    try {
      await loginWithPopup();
    } catch (error) {
      console.log("Login failed: " + error)
    }
  };

  // Logout function

  const handleLogout = async () => {
    try {
      await logout({
        logoutParams: {
          returnTo: window.location.origin 
        }
      });
    } catch (error) {
      console.log("Logout failed: " + error)
    }
  };

  // Get Access token

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        
        try {
          const accessToken = await getAccessTokenSilently();
          setToken(accessToken);
          sessionStorage.setItem('token', accessToken);
        } catch (error) {
          console.error("Error during token retrieval:", error);
        }

      }
    };

    fetchToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <AuthContext.Provider value={{ token, user, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
