import React, { useContext, useState, useEffect, Fragment, useCallback } from "react";
import { Loader, Alert, IconButton, Button, Snackbar } from '@heathmont/moon-core-tw';
import { ArrowsLeftShort, GenericAlarmRound, GenericInfo, GenericPlus, FilesSave, GenericEdit, GenericCheckRounded, GenericAlarm } from '@heathmont/moon-icons-tw';
import { AuthContext } from "../providers/profileProvider";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import DataComponent from "../components/DataComponent";
import DataForm from "../components/DataForm";
import DataSettings from "../components/DataSettings";
import DataInput from "../components/DataInput";
import ModuleTable from "../components/ModuleTable";



const EventDetail = () => {

    const { moduleId } = useParams();
    const { token } = useContext(AuthContext);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const [windowSize, setWindowSize] = useState('sm');

    useEffect(() => {
      const checkSize = () => {
        if (window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches) {
            setWindowSize('md');
        } else if (window.matchMedia('(min-width: 1024px)').matches) {
            setWindowSize('lg');
        } else {
            setWindowSize('sm');
        }
      };
      checkSize();
      window.addEventListener('resize', checkSize);
      return () => window.removeEventListener('resize', checkSize);
    }, []);
  


    const navigate = useNavigate();

    function handleRedirect(eventId) {
        navigate(`/event/${eventId}`);
    }

    const [module, setModule] = useState();
    const [isLoading, setIsLoading] = useState(true);

    function formatDate(inputDate) {
        const parts = inputDate.split("-");
        const europeanDate = `${parts[2]}.${parts[1]}.${parts[0]}`;
        return europeanDate;
    }

    const [snackbar, setSnackbar] = useState("");

    const openSnackbarHandler = useCallback(
        (type) => {
            if (snackbar) {
                setSnackbar('');
                setTimeout(() => {
                    setSnackbar(type);
                }, 400);
            } else {
                setSnackbar(type);
            }
        },
        [snackbar]
    );
    

    useEffect(() => {

        if (token?.length > 0) {


            axios.get(`https://eventio.hasura.app/api/rest/module/${moduleId}`)
                .then(res => {
                    setIsLoading(false);
                    let resModule = res.data?.modules_by_pk;
                    if (resModule) {
                        setModule(resModule);
                    }
                }).catch(error => {

                    console.error("Chyba pri načítaní údajov:", error)
                    setIsLoading(false);

                });

        }

    }, [token, moduleId]);



    const [editMode, setEditMode] = useState(false);

    const closeEditMode = () => setEditMode(false);
    const openEditMode = () => setEditMode(true);

    const [dataModal, setDataModal] = useState(false);
    const openDataModal = () => setDataModal(true);
    const closeDataModal = () => setDataModal(false);

    function handleUpdate() {

        const dataToSend = {
            moduleData: module.moduleData
        }

        axios.post(`https://eventio.hasura.app/api/rest/module/update/moduledata/${moduleId}`, dataToSend)
            .then(response => {
                if (response.data) {
                    openSnackbarHandler("uspesne-aktualizovane");
                    setEditMode(false);
                }
            })
            .catch(error => {
                console.error("Chyba pri odosielaní údajov na server:", error);
                openSnackbarHandler("chyba-server");
            });

    }

    function handleChange(itemName, newValue) {
        setModule(prevModule => {
            const updatedItems = prevModule.moduleData.items.map(item => {
                if (item.name === itemName) {
                    return { ...item, value: newValue };
                }
                return item;
            });

            return {
                ...prevModule,
                moduleData: {
                    ...prevModule.moduleData,
                    items: updatedItems
                }
            };
        });
    };




    return(
        <Fragment>

            {

                isLoading ? (
                    <Loader />
                ) : (
                    <>

                        <div className="flex justify-between items-center mb-8">
                            <div>
                                <p onClick={() => handleRedirect(module.eventModules.id)} className="text-moon-16 lg:text-moon-20 font-medium mb-3 cursor-pointer flex flex-row items-center transition-colors text-bulma hover:text-eventio-primary"><ArrowsLeftShort className="text-moon-24 relative b-1 mr-2" /> {module.eventModules?.name ? module.eventModules?.name : "..."}</p>
                                <h3 className="text-moon-24 lg:text-moon-32 font-medium transition-colors mb-2">{module?.name ? module.name : "Modul nenájdený"}</h3>
                                { module.description && <p className="text-moon-14 lg:text-moon-16">{ module.description }</p>}
                            </div>
                        </div>

                        {module ? (
                            <>
                            
                            {/* Data modulu */}



                            {(module.type == "data" || module.type == "dataTable") && (
                                <>
                                    <div className="flex justify-between items-center mb-4">
                                        <h4 className="text-moon-20 md:text-moon-20 lg:text-moon-24 font-medium transition-colors">Údaje:</h4>

                                        {editMode && (
                                            <div className="ml-4 flex justify-end">
                                                <IconButton className="bg-eventio-primary" icon={<GenericPlus />} onClick={openDataModal} />
                                                <Button onClick={handleUpdate} className="ml-2 bg-roshi-60" iconLeft={<FilesSave />}>Uložiť</Button>
                                            </div>
                                        ) || (
                                                <IconButton className="bg-gohan" onClick={openEditMode} icon={<GenericEdit className="text-popo" />} />
                                            )}

                                    </div>

                                    {/* Formulár na pridanie nového údaju */}

                                    <DataForm type="module" setModule={setModule} closeDataModal={closeDataModal} dataModal={dataModal} />


                                    {/* Všetky údaje */}

                                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">

                                        {module.moduleData?.items?.length > 0 ? (
                                            module.moduleData?.items?.map((item) => (
                                                <div style={{ gridColumn: `span ${windowSize == "sm" ? (item.format == "1" ? "1" : "2") : item.format}` }} className={`bg-goku rounded-2xl py-3 px-4 md:py-6 md:px-6`} key={item.name}>

                                                    <div className="flex justify-between">

                                                        <p className="text-zeno text-moon-14 m-0">{item.name}: </p>

                                                        {editMode && (
                                                            <DataSettings type="module" event={module} setModule={setModule} item={item}/>
                                                        )}

                                                    </div>


                                                    {/* Vykreslenie konkrétnych hodnôt / inputov */}

                                                    {editMode && (

                                                        <DataInput item={item} handleChange={handleChange} />

                                                    ) || (

                                                        <DataComponent item={item} formatDate={formatDate} />

                                                    )}

                                                </div>
                                            ))
                                        ) : (
                                            <Alert className="bg-beerus col-span-6">
                                                <Alert.Message>
                                                    <GenericInfo className="mr-1" />
                                                    Pri tejto udalosti nemáte pridané žiadné doplňujúce údaje.
                                                </Alert.Message>
                                            </Alert>
                                        )}

                                    </div>
                                </>
                            )}


                            {(module.type == "table" || module.type == "dataTable") && (
                                <>
                                    
                                    <ModuleTable moduleId={moduleId} module={module} moduleColumns={module.tableSchema?.columns ? module.tableSchema.columns : null} moduleData={module.records?.items ? module.records.items : null} setModule={setModule} openSnackbarHandler={openSnackbarHandler} />

                                </>
                            )}



                            </>
                        ) : (
                            <Alert className="bg-dodoria-10">
                                <Alert.Message>
                                    <GenericAlarmRound className="text-moon-24" />
                                    Modul nebol nájdený alebo k nemu nemáte prístupové práva.
                                </Alert.Message>
                            </Alert>
                        )}
                    </>
                )

            }

            {/* Alerts */}

            <Snackbar isOpen={snackbar === 'uspesne-aktualizovane'} onOpenChange={setSnackbar} position="bottom-right">
                <Snackbar.Icon>
                    <GenericCheckRounded className="text-roshi" />
                </Snackbar.Icon>
                <Snackbar.Message>Údaje boli úspešne uložené</Snackbar.Message>
            </Snackbar>

            <Snackbar isOpen={snackbar === 'chyba-server'} onOpenChange={setSnackbar} position="bottom-right">
                <Snackbar.Icon>
                    <GenericAlarm className="text-dodoria" />
                </Snackbar.Icon>
                <Snackbar.Message>Pri nahrávani údajov na server nastala chyba!</Snackbar.Message>
                <Snackbar.Message>Skúste to prosím neskôr</Snackbar.Message>
            </Snackbar>


        </Fragment>
    )



};

export default EventDetail;
