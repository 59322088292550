import React, { Fragment, useContext } from "react";
import { Button } from '@heathmont/moon-core-tw';
import eventioMockup from "../assets/eventio-mockup.png";
import { AuthContext } from "../providers/profileProvider";

const Unauthenticated = () => {


    const { handleLogin } = useContext(AuthContext);

    return (
        <Fragment>

            <div className="flex flex-col-reverse md:flex-row  justify-center items-center mt-4">

                <div className="max-w-[100%] md:max-w-[45%] lg:max-w-[60%]">
                    <img src={eventioMockup} alt="Aplikácia Eventio"/>
                </div>

                <div className="relative bottom-5 text-center md:text-left flex flex-col items-center md:items-start">
                    <h3 className="text-eventio-primary font-medium transition-colors font-['Josefin_Sans'] leading-[28px] lg:leading-[64px] text-moon-24 mb-6 sm:text-moon-24 md:text-moon-32 lg:text-moon-48">Váš univerzálny <br />plánovač udalosti</h3>
                    <p className="text-moon-16 lg:text-moon-20 font-medium leading-8 mb-8">Naplánujte si vašu svadbu, oslavu, teambuilding či konferenciu rýchlo a jednoducho na pár klikov</p>
                    <Button className="bg-eventio-primary" onClick={handleLogin}>Prihlásiť sa</Button>
                </div>


            </div>



        </Fragment>
    )

};

export default Unauthenticated;
