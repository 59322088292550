import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react'
import { ProfileProvider } from './providers/profileProvider';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const authConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  authorizationParams: {
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECTURI,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
  },
};


root.render(
  <React.StrictMode>
    <Auth0Provider
      {...authConfig}
    >

      <ProfileProvider>
        <App />
      </ProfileProvider>

    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
