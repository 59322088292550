import React from "react";
import {Input, InsetInput, Textarea} from '@heathmont/moon-core-tw';

const DataInput = ({item, handleChange}) => {
    

    return (
        <>
                                        
        {item.type == "shortText" && (
            <Input 
                type="text" 
                value={ item.value }
                onChange={(e) => handleChange(item.name, e.target.value)}
                className="text-moon-16 font-semibold m-0 mt-2" 
            />
        )}

        {item.type == "longText" && (
            <Textarea 
                value={ item.value }
                onChange={(e) => handleChange(item.name, e.target.value)}
                className="text-moon-16 font-semibold m-0 mt-2" 
            />
        )}

        {(item.type == "number" || item.type == "currencyEur" || item.type == "currencyCzk") && (
            <Input 
                type="number" 
                value={ item.value }
                onChange={(e) => handleChange(item.name, e.target.value)}
                className="text-moon-16 font-semibold m-0 mt-2 outline-4" 
            />
        )}

        {item.type == "date" && (
            <InsetInput 
                type="date" 
                value={ item.value }
                onChange={(e) => handleChange(item.name, e.target.value)}
                className="text-moon-16 font-semibold m-0 mt-2" 
            />
        )}

        {item.type == "url" && (
            <Input 
                type="url" 
                value={ item.value }
                onChange={(e) => handleChange(item.name, e.target.value)}
                className="text-moon-16 font-semibold m-0 mt-2" 
            />
        )}
        
        
        </>
    )

}

export default DataInput;