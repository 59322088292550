import React from "react";

const DataComponent = ({item, formatDate}) => {
    

    return (
        <>
                                        
            {(item.type == "shortText" || item.type == "longText" || item.type == "number" ) && (
                <p className="text-moon-16 font-semibold m-0">{ item.value }</p>
            )}

            {item.type == "date" && (
                <p className="text-moon-16 font-semibold m-0">{ formatDate(item.value) }</p>
             )}

             {item.type == "currencyEur" && (
                 <p className="text-moon-16 font-semibold m-0">{ item.value } €</p>
             )}

             {item.type == "currencyCzk" && (
                 <p className="text-moon-16 font-semibold m-0">{ item.value } Kč</p>
             )}

             {item.type == "url" && (
                <a href={item.value} target="_blank" rel="noopener noreferrer" className="text-moon-16 font-semibold m-0 text-eventio-primary underline">URL Link</a>
             )}

        
        </>
    )

}

export default DataComponent;