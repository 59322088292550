import React from "react";
import { ControlsChevronRight } from "@heathmont/moon-icons-tw";
import { useNavigate } from 'react-router-dom';

const EventItem = ({id, name, place = null, date = null, dateTo = null, time = null  }) => {

    const navigate = useNavigate();

    function handleRedirect(eventId) {
        navigate(`/event/${eventId}`);
    }
  
    function formatDate(inputDate) {
        const parts = inputDate.split("-");
        const europeanDate = `${parts[2]}.${parts[1]}.${parts[0]}`;
        return europeanDate;
    }


    return (
        <>
        
            <div className="hidden md:flex event-item bg-goku cursor-pointer w-full justify-between items-center rounded-2xl mb-8 py-3 px-8 transition-shadow shadow-md hover:shadow-lg" onClick={() => handleRedirect(id)}>

                <div className="event-item-content flex justify-start items-center min-h-16 flex-wrap">
                    
                    <div className="event-item-heading mr-8 py-3">
                        <h2 className="text-moon-20 font-semibold m-0 relative top-0.5">{ name }</h2>
                        {place && (<p className="text-zeno text-moon-16">{place}</p>)}
                    </div>

                    <div className="flex py-3">
                        {date && (
                            <div className="event-item-data pl-8 border-l-2 mr-8">
                                <p className="text-zeno text-moon-14 m-0">{ dateTo ? "Dátum od" : "Dátum" }</p>
                                <p className="text-moon-16 font-semibold m-0">{ formatDate(date) }</p>
                            </div>
                        )}

                        {dateTo && (
                            <div className="event-item-data pl-8 border-l-2 mr-8">
                                <p className="text-zeno text-moon-14 m-0">Dátum do</p>
                                <p className="text-moon-16 font-semibold m-0">{ formatDate(dateTo) }</p>
                            </div>
                        )}

                        {time && (
                            <div className="event-item-data pl-8 border-l-2 mr-8">
                                <p className="text-zeno text-moon-14 m-0">Čas</p>
                                <p className="text-moon-16 font-semibold m-0">{ time.slice(0, 5) }</p>
                            </div>
                        )}
                    </div>

                </div>    

                <ControlsChevronRight className="text-zeno text-moon-24" />
            </div>

            <div className="flex md:hidden flex-col event-item bg-goku cursor-pointer w-full justify-between items-center rounded-2xl mb-8 py-3 px-6 transition-shadow shadow-md hover:shadow-lg" onClick={() => handleRedirect(id)}>

                <div className="event-item-content flex justify-start items-center min-h-16 flex-wrap w-full">
                    
                    <div className="event-item-heading w-full m-0 pb-2 mb-2 border-b-2">
                        <h2 className="text-moon-16 font-semibold m-0 relative top-0.5">{ name }</h2>
                        {place && (<p className="text-zeno text-moon-14">{place}</p>)}
                    </div>

                    <div className="flex *:mr-4 flex-wrap">
                        {date && (
                            <div className="event-item-data flex">
                                <p className="text-zeno text-moon-14 m-0 mr-2">{ dateTo ? "Dátum od" : "Dátum" }: </p>
                                <p className="text-moon-14 font-semibold m-0">{ formatDate(date) }</p>
                            </div>
                        )}

                        {dateTo && (
                            <div className="event-item-data flex">
                                <p className="text-zeno text-moon-14 m-0 mr-2">Dátum do: </p>
                                <p className="text-moon-14 font-semibold m-0">{ formatDate(dateTo) }</p>
                            </div>
                        )}

                        {time && (
                            <div className="event-item-data flex">
                                <p className="text-zeno text-moon-14 m-0 mr-2">Čas: </p>
                                <p className="text-moon-14 font-semibold m-0">{ time.slice(0, 5) }</p>
                            </div>
                        )}
                    </div>

                </div>    

            </div>

        </>
    )

}

export default EventItem;