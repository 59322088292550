import React, { Fragment, useContext, useEffect, useState } from "react";
import { Loader, Alert, Button, IconButton } from '@heathmont/moon-core-tw';
import { GenericInfo, ControlsPlus } from '@heathmont/moon-icons-tw';

import { AuthContext } from "../providers/profileProvider";
import EventForm from "../components/EventForm";
// import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';

import EventItem from "../components/EventItem";

const Home = () => {

  const { token, user } = useContext(AuthContext);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;




  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // %%  Get all events from API  %%%
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    if(token?.length > 0){

    
    axios.get(`https://eventio.hasura.app/api/rest/events`)
      .then(res => {
        setIsLoading(false);
        setData(res.data);
      
      }).catch(error => {
        
        console.error("Chyba pri načítaní údajov:", error)
        setIsLoading(false);
    
      });

    }

  }, [token]);

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%



  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //          EVENT MODAL
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);


  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%



  return(
    <Fragment>
    
      <div className="flex justify-between items-center mb-8">
        <h3 className="text-moon-24 lg:text-moon-32 font-medium transition-colors">Moje udalosti:</h3>
        <Button onClick={openModal} className="hidden md:flex ml-4 bg-eventio-primary" iconLeft={<ControlsPlus />}>Nová udalosť</Button>
        <IconButton onClick={openModal} size="sm" className="block md:hidden ml-4 bg-eventio-primary" icon={<ControlsPlus />} />
      </div>

      <EventForm type="create" setData={setData} closeModal={closeModal} isModalOpen={isModalOpen} setModalOpen={setModalOpen} />

      {/*************************
            List all events 
      *************************/}


      {

        isLoading ? (
          <Loader />
        ) : (
          
          <div className="events mb-14">

              {console.log(data.events)}
              {data.events.filter((event) => event.userEvents?.email == user?.email).length > 0 ? (
                data.events.filter((event) => event.userEvents?.email == user?.email).map((event) => (
                  <EventItem
                    key={event.id}
                    id={event.id}
                    name={event.name}
                    place={event.place}
                    date={event.date}
                    dateTo={event.dateTo}
                    time={event.time}
                  />
                ))
              ) : (
                <Alert className="bg-dodoria-10">
                    <Alert.Message>
                        <GenericInfo className="text-moon-24" />
                        Nemáte vytvorenú žiadnú udalosť.
                    </Alert.Message>
                </Alert>
              )}

          </div>

        )

      }

      {/*************************/}





      <div className="flex justify-between items-center mb-8">
        <h3 className="text-moon-24 lg:text-moon-32 font-medium transition-colors">Zdieľané udalosti:</h3>
      </div>

      {/*************************
          List shared events 
      *************************/}


      {

        isLoading ? (
          <Loader />
        ) : (
          
          <div className="events">

              {data.events.filter((event) => event.userEvents?.email != user?.email).length > 0 ? (
                data.events.filter((event) => event.userEvents?.email != user?.email).map((event) => (
                  <EventItem
                    key={event.id}
                    id={event.id}
                    name={event.name}
                    place={event.place}
                    date={event.date}
                    dateTo={event.dateTo}
                    time={event.time}
                  />
                ))
              ) : (
                <Alert className="bg-nappa-10">
                    <Alert.Message>
                        <GenericInfo className="text-moon-24" />
                        Nemáte nazdieľanu žiadnú udalosť
                    </Alert.Message>
                </Alert>
              )}

          </div>

        )

      }

      {/*************************/}


    
    </Fragment>
  )
 
};

export default Home;
