import React, { useState } from "react";
import { IconButton, Button, Textarea, Radio, Form, Modal, InsetInput } from '@heathmont/moon-core-tw';
import { GenericPlus } from '@heathmont/moon-icons-tw';
import { ControlsCloseSmall } from '@heathmont/moon-icons-tw';

import axios from 'axios';


const ModuleForm = ({setEvent, eventId, openSnackbarHandler}) => {

    const [moduleModal, setModuleModal] = useState(false);
    const openModuleModal = () => setModuleModal(true);
    const closeModuleModal = () => setModuleModal(false);

    const [moduleForm, setModuleForm] = useState({
        "name": "",
        "type": "data",
        "description": ""
    });

    function handleInputChange(event) {
        const { name, value } = event.target;
        setModuleForm(prevmoduleForm => ({
            ...prevmoduleForm,
            [name]: value
        }));
    }


    function handleCreate(){

        const dataToSend = {
            eventId: eventId,
            name: moduleForm.name,
            type: moduleForm.type,
            description: moduleForm.description ? moduleForm.description : null
        }
    
        axios.post("https://eventio.hasura.app/api/rest/module/create", dataToSend)
          .then(response => {
            console.log("Odpoveď od servera:", response.data);
            
            let newModule = response.data.insert_modules_one;

            if(newModule){
                    
                    setEvent(prevEvent => {
                        return {
                        ...prevEvent,
                        modules: [
                            ...prevEvent.modules || [],
                            newModule
                        ]
                        };
                    });
    
    
                setModuleModal(false);
        
                setModuleForm({
                    "name": "",
                    "type": "data",
                    "description": ""
                })

              openSnackbarHandler("modul-pridany");
            }

    
    
          })
          .catch(error => {
            console.error("Chyba pri odosielaní údajov na server:", error);
            
            setModuleModal(false);
            openSnackbarHandler("chyba-server");
          });
    
      }


    return (
        <>


            {/* Pridanie nového údaju */}


            <Modal open={moduleModal} onClose={closeModuleModal}>
                <Modal.Backdrop />
                <Modal.Panel className="max-w-md">

                    <div className="border-b-[0.063rem] border-beerus pt-5 pb-4 px-6 relative">
                        <h3 className="text-moon-18 text-bulma font-medium">Nový modul</h3>
                        <IconButton
                            variant="ghost"
                            size="sm"
                            className="absolute top-4 end-5"
                            onClick={closeModuleModal}
                        >
                            <ControlsCloseSmall className="text-moon-24" />
                        </IconButton>
                    </div>

                    <div className="w-full flex flex-col gap-3">

                        <Form
                            className="w-full p-5 flex flex-col gap-2"
                            id="newDataItem"
                        >

                            <Form.Item>
                                <InsetInput placeholder="Zadajte názov modulu" name="name" value={moduleForm.name} onChange={handleInputChange}>
                                    <InsetInput.Label>Názov modulu <i className="text-dodoria font-semibold">*</i></InsetInput.Label>
                                </InsetInput>
                            </Form.Item>


                            <p className="text-moon-14 px-2 pt-2">Typ:</p>

                            <Form.Item>
                                <Radio className="flex flex-col w-full" name="type" value={moduleForm.type} onChange={(value) => handleInputChange({ target: { name: "type", value: value } })}>
                                    <Radio.Option value="data" className="bg-eventio-bg-light w-full flex justify-start items-center rounded-lg mb-2 py-3 px-3">
                                        <Radio.Indicator />
                                        <div>
                                            <p className="text-moon-14 font-semibold m-0">Údajový modul</p>
                                            <p className="text-moon-14 m-0">Slúži na kategorizáciu blokových údajov</p>
                                        </div>
                                    </Radio.Option>
                                    <Radio.Option value="table" className="bg-eventio-bg-light w-full flex justify-start items-center rounded-lg mb-2 py-3 px-3">
                                        <Radio.Indicator />
                                        <div>
                                            <p className="text-moon-14 font-semibold m-0">Tabuľkový modul</p>
                                            <p className="text-moon-14 m-0">Flexibilné tabuľkové zobazenie záznamov</p>
                                        </div>
                                    </Radio.Option>
                                    <Radio.Option value="dataTable" className="bg-eventio-bg-light w-full flex justify-start items-center rounded-lg mb-2 py-3 px-3">
                                        <Radio.Indicator />
                                        <div>
                                            <p className="text-moon-14 font-semibold m-0">Kombinovaný modul</p>
                                            <p className="text-moon-14 m-0">Kombinácia blokových a tabuľkových údajov</p>
                                        </div>
                                    </Radio.Option>
                                </Radio>
                            </Form.Item>


                            <p className="text-moon-14 px-2 pt-2">Popis:</p>
                            <Textarea
                                value={moduleForm.description} onChange={(e) => handleInputChange({ target: { name: "description", value: e.target.value } })}
                                className="text-moon-14 m-0"
                            />

                        </Form>

                    </div>


                    <div className="flex gap-2 p-4 justify-end pt-2">
                        <Button variant="outline" onClick={closeModuleModal}>
                            Zrušiť
                        </Button>
                        <Button disabled={moduleForm.name.trim() !== "" ? false : true} className="bg-eventio-primary" onClick={handleCreate}>Vytvoriť modul</Button>
                    </div>


                </Modal.Panel>

            </Modal>


            <IconButton className="bg-gohan" onClick={openModuleModal} icon={<GenericPlus className="text-popo" />} />

        </>
    )

}

export default ModuleForm