import React, {useContext} from "react";
import eventioLogo from "../assets/eventio-color-horizontal.svg";
import { IconButton } from "@heathmont/moon-core-tw";
import { GenericLogOut, GenericUser, GenericHome } from '@heathmont/moon-icons-tw';
import { AuthContext } from "../providers/profileProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from 'react-router-dom';



const Header = () => {
const location = useLocation();
const navigate = useNavigate();
const { isAuthenticated } = useAuth0(); 
const { handleLogin, handleLogout } = useContext(AuthContext);

function handleHomeRedirect(){
    navigate("/");
}

return (
    <div className="w-full bg-eventio-bg-blue py-3">
        <div className='eventio-container'>
            <div className="flex flex-row justify-center items-center">

                { isAuthenticated && location.pathname !== "/" && (
                    <div className="flex flex-row items-center justify-end absolute left-5">
                        <IconButton size="sm" icon={<GenericHome/>} className="bg-eventio-primary mr-1" onClick={handleHomeRedirect}/>
                    </div>
                )}

                <img src={eventioLogo} alt="Eventio App Logo" onClick={handleHomeRedirect} className="w-32 md:w-44 cursor-pointer" />
                {
                    isAuthenticated ? (
                        <div className="flex flex-row items-center justify-end absolute right-5">
                            <IconButton size="sm" icon={<GenericLogOut/>} className="bg-eventio-red" onClick={handleLogout}/>
                        </div>
                    ) : (
                        <div className="flex flex-row items-center justify-end absolute right-5">
                            <IconButton size="sm" icon={<GenericUser/>} className="bg-eventio-primary" onClick={handleLogin}/>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
)

};

export default Header;