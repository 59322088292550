// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider, Outlet} from 'react-router-dom';
import Header from './components/Header';
import Home from './views/Home';
import EventDetail from './views/EventDetail';
import ModuleDetail from './views/ModuleDetail';
import Unauthenticated from './views/Unauthenticated';
import NotFound from './views/NotFound';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isAuthenticated } = useAuth0();
  

  const Layout = () => (
    <div className="App">
      <Header />
      <div className='bg-eventio-bg-light min-h-screen'>
        <div className='eventio-container py-12'>
          <Outlet />
        </div>
      </div>
    </div>
  );


  const [router, setRouter] = useState(createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Unauthenticated /> },
        { path: "*", element: <NotFound /> }
      ],
    },
  ]));

  useEffect(() => {
    const routes = isAuthenticated ? [
      {
        path: "/",
        element: <Layout />,
        children: [
          { path: "/", element: <Home />, index: true },
          { path: "event/:eventId", element: <EventDetail /> },
          { path: "module/:moduleId", element: <ModuleDetail /> },
          { path: "*", element: <NotFound /> }
        ],
      },
    ] : [
      {
        path: "/",
        element: <Layout />,
        children: [
          { path: "/", element: <Unauthenticated /> },
          { path: "*", element: <NotFound /> }
        ],
      },
    ];

    const newRouter = createBrowserRouter(routes);
    setRouter(newRouter);
  }, [isAuthenticated]);

  

  return <RouterProvider router={router} />;
}


export default App;
